var AbiLab = {
    init: function () {
        this.main.init();
    },
    main: {
        init: function () {
            this.header();
            this.ofi();
            this.slider();
            this.select2();
            this.tassonomia();
            this.countup();
            this.aos();
            this.search();
            this.area();
            this.evento();
            this.areeDiRicerca();
            this.accordion();
            this.insight();
            this.varie();
            this.editoriale();
        },
        editoriale: function (){
            $(window).scroll(function() {
                $('.editoriale-header').each(function() {
                    const thisArea = $(this);
                    if (!$(this).hasClass('without-image')){
                        scrollTop     = $(window).scrollTop(),
                        _actualScroll = (scrollTop / 3) - 150;
                        thisArea.css('background-position', "top "+_actualScroll+"px center");
                    };
                });
            });
            $('.swiper-with-thumbs').each(function(){
                const thumbs = $(this).find('.gallery-thumbs');
                const topSwiper = $(this).find('.gallery-top');
                const swiperPrev = $(this).find('.swiper-prev');
                const swiperNext = $(this).find('.swiper-next');
                var galleryThumbs = new Swiper(thumbs, {
                    spaceBetween: 15,
                    slidesPerView: 3,
                    freeMode: true,
                    loopedSlides: 5, //looped slides should be the same
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    breakpoints: {
                        992: {
                        slidesPerView: 6,
                        spaceBetween: 30,
                        }
                    }
                });
                var galleryTop = new Swiper(topSwiper, {
                    spaceBetween: 0,
                    loop:true,
                    autoHeight: true,
                    loopedSlides: 5, //looped slides should be the same
                    navigation: {
                        prevEl: swiperPrev,
                        nextEl: swiperNext,
                    },
                    thumbs: {
                        swiper: galleryThumbs,
                    },
                });
            });
        },
        varie: function () {
            var distance = $('.partner-sticky').offset().top - 100,
                distanceBottom = $('.header').outerHeight() + $('.dettaglio-partner').outerHeight(),
                $window = $(window);
            $(window).scroll(function() {
                console.log(distanceBottom);
                console.log($window.scrollTop());
                
                if ( $window.scrollTop() >= distance ) {
                    $('.partner-sticky').addClass('sticked');
                } else {
                    $('.partner-sticky').removeClass('sticked');
                }
                
                if ($window.scrollTop() > distanceBottom ) {
                    $('.partner-sticky').addClass('absoluted');
                } else {
                    $('.partner-sticky').removeClass('absoluted');
                }

            });
        },
        evento: function () {
            $(window).scroll(function() {
                
                $('.evento-header').each(function() {
                    
                    const thisArea = $(this);

                    if (!$(this).hasClass('without-image')){
                        scrollTop     = $(window).scrollTop(),
                        _actualScroll = (scrollTop / 3) - 150;
    
                        thisArea.css('background-position', "top "+_actualScroll+"px center");
                    }

                });

            });

        },
        area: function () {
            $(window).scroll(function() {
                $('.area-header-bg').each(function() {
                    const thisArea = $(this);
                    scrollTop     = $(window).scrollTop(),
                    _actualScroll = (scrollTop / 2) + 150;
                    thisArea.find('.area-header').css('background-position', "bottom -"+_actualScroll+"px center");
                });
            });
        },
        insight: function () {

            $('#filtro-insight .close').on('click', function () { 
                $('#filtro-insight').modal('toggle');
            });

            $('.filtri-attivi .btn').on('click touchstart', function (e) {
                e.preventDefault();
                console.log('rimosso filtro');
                const thisRemove = $(this);
                thisRemove.parent('.singolo-filtro').fadeOut(function(){
                    thisRemove.parent('.singolo-filtro').remove();
                });
            });

            $('.lista-modale-filtri .btn').on('click touchstart', function (e) { 
                e.preventDefault();
                const bottoneFiltro = $(this);
                if ( $(this).hasClass('delete')){
                    bottoneFiltro.removeClass('delete');
                    bottoneFiltro.addClass('transparent');
                } else {
                    bottoneFiltro.addClass('delete');
                    bottoneFiltro.removeClass('transparent');

                }
            });

            // Slider blue section
            $('.insight-blue-section').each(function(){

                const $this = $(this);
                const thisSwiper = $(this).find('.swiper-container');
                const thisSwiperWrapper = $(this).find('.swiper-report-insight');
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');

                function space(){
                    var _actuaPadding = $this.find('.head-filtri').position().left;
                    thisSwiper.css('padding-left', _actuaPadding);
                    thisSwiper.css('padding-right', _actuaPadding);
                }

                space();

                $(window).on('resize', function(){
                    space();
                });

                var swiper = new Swiper(thisSwiper, {
                    slidesPerView: 'auto'
                });

            });

            // Slider documenti
            $('.swiper-documenti-insight').each(function(){

                const $this = $(this);
                const thisSwiper = $(this).find('.swiper-container');
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');

                function space(){
                    var _actuaPadding = $this.find('.head-documenti').position().left;
                    thisSwiper.css('padding-left', _actuaPadding);
                    thisSwiper.css('padding-right', _actuaPadding);
                }

                space();

                $(window).on('resize', function(){
                    space();
                });

                var swiper = new Swiper(thisSwiper, {
                    slidesPerView: 'auto'
                });

            });

            // Slider approfondimenti
            $('.swiper-approfondimenti-insight').each(function(){

                const $this = $(this);
                const thisSwiper = $(this).find('.swiper-container');
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');

                function space(){
                    var _actuaPadding = $this.find('.head-approfondimenti').position().left;
                    thisSwiper.css('padding-left', _actuaPadding);
                    thisSwiper.css('padding-right', _actuaPadding);
                }

                space();

                $(window).on('resize', function(){
                    space();
                });

                var swiper = new Swiper(thisSwiper, {
                    slidesPerView: 'auto'
                });

            });
            
        },
        accordion: function () {
            $(".accordion").each(function () {
                $(this).find('.collapse').on('show.bs.collapse', function () {
                    $(this).prev().addClass('active');
                });
                $(this).find('.collapse').on('hide.bs.collapse', function () {
                    $(this).prev().removeClass('active');
                });

            });
        },
        areeDiRicerca: function () {
            $(window).scroll(function() {
                $('.dettaglio-area-di-ricerca').each(function() {
                    const thisArea = $(this);
                    scrollTop     = $(window).scrollTop(),
                    elementOffset = $(this).find('.dettaglio-area-di-ricerca-col .btn').offset().top + 100,
                    distance      = (elementOffset - scrollTop);
                    _actualScroll = Math.abs(distance / 9) - 15;
                    _actualScrollFix = _actualScroll;

                    console.log(_actualScrollFix);
                    
                    if ($(this).offset().top < ($(window).scrollTop() + ($(window).height() / 2) + 200)) {
                        thisArea.css('background-position', "bottom "+_actualScrollFix+"px center");
                    }

                });
            });
        },
        search: function () {
            
            $('#search-form .close').on('click', function () { 
                $('#search-form').modal('toggle');
            });

            $('.risultati-ricerca-head .clear').on('click', function (e) { 
                e.preventDefault();
                $('.risultati-ricerca-head').find('input').val("");
                $('.risultati-ricerca-head').removeClass('filled');
            });

            $('.risultati-ricerca-head input').on('input', function() {
                var value = $(this);
                console.log(value);
                if (value.val() !== "") {
                    $('.risultati-ricerca-head').addClass('filled');
                } else {
                    $('.risultati-ricerca-head').removeClass('filled');
                }
            });

        },
        aos: function () {
            var windowHeight = $(window).height() / 5;
            AOS.init({
                offset: windowHeight , // offset (in px) from the original trigger point
                delay: 0, // values from 0 to 3000, with step 50ms
                duration: 1500, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
            });
        },
        countup: function () {

            $(window).scroll(function() {

                if( $('.home-visual .number').length){
                    var options = {  
                        useEasing: true,
                        useGrouping: true,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                        suffix: ''
                    };
    
                    $('.home-visual .number').each(function() {
                        const thisNumber = this;
                        if ($(this).offset().top < ($(window).scrollTop() + ($(window).height() / 2) + 200)) {
                            const valNum = $(this).data('number-value').toString();
                            var nuen = $(this).text();
                            if (nuen === '') {
                            nuen = 0;
                            }
                            var counts = new CountUp(thisNumber, nuen, valNum, 0, 1.5, options);
                            counts.start();
                        }
                    });
                }
                
    
            });

        },
        tassonomia: function () {
            $('.titolo-info-tassonomia span').on('click', function(){
                if ($(this).parent('.titolo-info-tassonomia').next('.singola-tassonomia').length && $(this).parents('.singola-tassonomia').hasClass('with-child')){
                    if ($(this).parent('.titolo-info-tassonomia').parent('.singola-tassonomia').hasClass('open')){
                        $(this).parent('.titolo-info-tassonomia').parent('.singola-tassonomia').removeClass('open');
                        $(this).parent('.titolo-info-tassonomia').parent('.singola-tassonomia').find('> .singola-tassonomia').slideUp();
                    } else {
                        $(this).parent('.titolo-info-tassonomia').parent('.singola-tassonomia').addClass('open');
                        $(this).parent('.titolo-info-tassonomia').parent('.singola-tassonomia').find('> .singola-tassonomia').slideDown();
                    }
                }
            });

            $('.singola-tassonomia .info').on('click', function(e){
                e.preventDefault();
                const thisTitle = $(this).find('.title').html();
                const thisDescription = $(this).find('.description').html();
                $('body').append('<div class="modal-info"><div class="container"><span class="close"></span><span class="title">'+thisTitle+'</span><span class="description">'+thisDescription+'</span></div></div>');
                $('body').addClass('modal-info-open');
                setTimeout(function(){
                    $('.modal-info').addClass('visible');
                }, 0);
            });

            $(document).on('click', '.modal-info .close', function(){
                $('.modal-info').removeClass('visible');
                $('body').removeClass('modal-info-open');
                setTimeout(function(){
                    $('.modal-info').remove();
                }, 500);
            });

        },
        select2: function () {
            $('.filtro-archivi').select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: "archivio"
            });
            
            $('select.form-control').select2({
                minimumResultsForSearch: -1,
                placeholder: "Select a value",
                dropdownCssClass: "generic-select"
            });
        },
        slider: function () {

            $('.swiper-report').each(function(){
                const $this = $(this);
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');
                const thisSwiper = $(this).find('.swiper-container');
                var swiper = new Swiper(thisSwiper, {
                    loop: 'loop',
                    effect: 'slide',
                    speed: 1000,
                    centeredSlides: true,
                    loopAdditionalSlides: 0,
                    loopedSlides: 0,
                    slidesPerView: 1,
                    allowTouchMove: true,
                    autoHeight: 'auto',
                    breakpoints: {
                        993: {
                            allowTouchMove: false
                        }
                    },
                    navigation: {
                        prevEl: $thisPrev,
                        nextEl: $thisNext,
                    },
                    on: {
                        slideChange: function (e){

                            swiper.on('slideChange', function() {
                                $this.find('.swiper-slide').each(function(){
                                    const $thisSlide = $(this);
                                    // setTimeout(function(){
                                        $thisSlide.removeClass('move-to-left-animation');
                                        $thisSlide.removeClass('move-to-left');
                                        $thisSlide.removeClass('move-to-right-animation');
                                        $thisSlide.removeClass('move-to-right');
                                    // }, 100);
                                });
                            });

                            swiper.on('slideNextTransitionStart', function() {
                                setTimeout(function(){
                                    $this.find('.swiper-slide-active').addClass('move-to-left-animation');
                                }, 100);
                                setTimeout(function(){
                                    $this.find('.swiper-slide-active').addClass('move-to-left');
                                }, 1000);
                            });

                            swiper.on('slidePrevTransitionStart', function() {
                                setTimeout(function(){
                                    $this.find('.swiper-slide-active').addClass('move-to-right-animation')
                                }, 100);
                                setTimeout(function(){
                                    $this.find('.swiper-slide-active').addClass('move-to-right')
                                }, 1000);
                            });
                            
                        },
                        click: function (e){

                            if($(e.target).hasClass('swiper-next')){
                                console.log('next');
                                swiper.on('slideNextTransitionStart', function() {
                                    setTimeout(function(){
                                        $this.find('.swiper-slide-active').addClass('move-to-left-animation');
                                    }, 100);
                                    setTimeout(function(){
                                        $this.find('.swiper-slide-active').addClass('move-to-left');
                                    }, 300);
                                });
                            } else {
                                console.log('prev');
                                swiper.on('slidePrevTransitionStart', function() {
                                    setTimeout(function(){
                                        $this.find('.swiper-slide-active').addClass('move-to-right-animation')
                                    }, 100);
                                    setTimeout(function(){
                                        $this.find('.swiper-slide-active').addClass('move-to-right')
                                    }, 300);
                                });
                            }
                        },
                        init: function (e) {
                            $this.find('.swiper-slide').each(function(){
                                const $thisSlide = $(this);
                                if ($thisSlide.hasClass('swiper-slide-active')) {
                                    const $thisActive = $(this);
                                    setTimeout(function(){
                                        $thisActive.addClass('move-to-left-animation');
                                        $thisActive.addClass('move-to-left');
                                    }, 100);
                                }
                            });
                        }
                    }
                });
            });

            $('.swiper-evento').each(function(){
                const $this = $(this);
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');
                const thisSwiper = $(this).find('.swiper-container');
                var swiper = new Swiper(thisSwiper, {
                    loop: 'loop',
                    slidesPerView: 1,
                    // spaceBetween: 15,
                    autoHeight: 'auto',
                    navigation: {
                        prevEl: $thisPrev,
                        nextEl: $thisNext,
                    },
                    on: {
                        click: function (e) {
                            
                            if ($(e.target).hasClass('swiper-prev')){
                                swiper.on('slideChangeTransitionEnd', function() {
                                    $this.find('.swiper-slide').each(function(){
                                        $(this).removeClass('to-right');
                                        if ($(this).index() === swiper.activeIndex) {
                                            $(this).addClass('to-left');
                                        }
                                    });
                                });
                            } else {
                                swiper.on('slideChangeTransitionEnd', function() {
                                    $this.find('.swiper-slide').each(function(){
                                        $(this).removeClass('to-left');
                                        if ($(this).index() === swiper.activeIndex) {
                                            $(this).addClass('to-right');
                                        }
                                    });

                                });
                            }
                        },
                    }
                });
            });

            $('.swiper-news').each(function(){
                const $this = $(this);
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');
                const thisSwiper = $(this).find('.swiper-container');
                var swiper = new Swiper(thisSwiper, {
                    loop: 'loop',
                    slidesPerView: 1,
                    autoHeight: 'auto',
                    navigation: {
                        prevEl: $thisPrev,
                        nextEl: $thisNext,
                    },
                    on: {
                        click: function (e) {
                            
                            // if ($(e.target).hasClass('swiper-prev')){
                            //     swiper.on('slideChangeTransitionEnd', function() {
                            //         $this.find('.swiper-slide').each(function(){
                            //             $(this).removeClass('to-right');
                            //             if ($(this).index() === swiper.activeIndex) {
                            //                 $(this).addClass('to-left');
                            //             }
                            //         });
                            //     });
                            // } else {
                            //     swiper.on('slideChangeTransitionEnd', function() {
                            //         $this.find('.swiper-slide').each(function(){
                            //             $(this).removeClass('to-left');
                            //             if ($(this).index() === swiper.activeIndex) {
                            //                 $(this).addClass('to-right');
                            //             }
                            //         });

                            //     });
                            // }
                        },
                    }
                });
            });

            $('.swiper-partner').each(function(){
                const $this = $(this);
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');
                const thisSwiper = $(this).find('.swiper-container');
                var swiper = new Swiper(thisSwiper, {
                    loop: 'loop',
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    breakpoints: {
                        992: {
                        slidesPerView: 5,
                        slidesPerGroup: 1
                        }
                    },
                    navigation: {
                        prevEl: $thisPrev,
                        nextEl: $thisNext,
                    }
                });
            });

            $('.aree-swiper').each(function(){

                const $this = $(this);
                const thisSwiper = $(this).find('.swiper-container');
                const $thisPrev = $(this).find('.swiper-prev');
                const $thisNext = $(this).find('.swiper-next');

                function space(){
                    var _actuaPadding = $this.find('.head-swiper').position().left;
                    thisSwiper.css('padding-left', _actuaPadding);
                    thisSwiper.css('padding-right', _actuaPadding);
                }

                space();

                $(window).on('resize', function(){
                    space();
                });

                var swiper = new Swiper(thisSwiper, {
                    slidesPerView: 'auto',
                    navigation: {
                        prevEl: $thisPrev,
                        nextEl: $thisNext,
                    }
                });

            });
            

        },
        ofi: function () {
            objectFitImages();
        },
        header: function () {

            var lastScrollTop = 0;

            function headerScroll(){
                
                if ($(document).scrollTop() > 300) {
                    $('.header').addClass('animation');
                    $('.header').addClass('hided');
                } else {
                    $('.header').removeClass('animation');
                }

                if ($(document).scrollTop() > 300) {
                    $('.header').addClass('negative');
                    var st = $(this).scrollTop();
                    if (st > lastScrollTop){
                        $('.header').removeClass('showed');
                    } else {
                        $('.header').addClass('showed');
                    }
                    lastScrollTop = st;
                } else if ($(document).scrollTop() == 0 && $('.header').hasClass('showed')){
                    $('.header').removeClass('fixed');
                    $('.header').removeClass('showed');
                    $('.header').removeClass('negative');
                    $('.header').removeClass('hided');
                }

                if ($(document).scrollTop() > 350) {
                    $('.header').addClass('fixed');
                    $('.header').removeClass('hided');
                }

            }

            headerScroll();
            
            $(window).scroll(function() {
                headerScroll();    
            });

            
            $('.main-menu > ul > li.with-menu > a').on('click touchstart', function(e){
                e.preventDefault();
                if( $(this).hasClass('active')){
                    $(this).removeClass('active');
                    $('body').removeClass('opened');
                    $('body').removeClass('opened-desk');
                } else {
                    $(this).parents('ul').find('a').removeClass('active');
                    $(this).addClass('active');
                    $('body').addClass('opened');
                    setTimeout(function(){
                        $('body').addClass('opened-desk');
                    }, 300);
                }
            });

            $('.main-menu .back').on('click touchstart', function(e){
                e.preventDefault();
                $(this).parent('.push-select').prev('a').removeClass('active');
                $('body').removeClass('opened-desk');
            });

            $('.burger').on('click touchstart', function(e){
                e.preventDefault();
                $('.main-menu > ul > li a').removeClass('active');
                if( $('body').hasClass('opened')){
                $('body').removeClass('opened');
                } else {
                $('body').addClass('opened');
                }
            });

            function headerBackground(){
                if ($(document).scrollTop() > 100) {
                $('body').addClass('scrolled');
                } else {
                $('body').removeClass('scrolled');
                }
            }

            headerBackground();

            $(window).scroll(function() {
                headerBackground();
            });

        }
    }
};

$(document).ready(function () {
    AbiLab.init();
});